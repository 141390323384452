import React from "react";
import Footer from "../Components/Footer";
import score_icon from "../Images/score-icon.png";
import menu_icon from "../Images/menu-icon.svg";
import Menu from "../Components/Menu";
import './Styllle.css'
const Terms = () => {
  return (
    <div className="text-unselect">
      <Menu one="inactive"two="inactive"three="inactive"four="inactive"five="active"six="inactive"/>
      <div className="container white-bg">
        <div className="cus-header">
          <div className="col-md-6 col-xs-6">
            <div className="page-icon">
              <img alt="logo" src={score_icon} /> T&C
            </div>
          </div>
          <div className="col-md-6 col-xs-6">
          <span
              className="cus-menu navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-expanded="false"
            >
              <img alt="logo" src={menu_icon} />
              <span className="cursor-pointer">menu</span>
            </span>
          </div>
        </div>
        <div className="container-area">
          <span className="cus-btn-blue container-area-span">
            {" "}
            Terms and Conditions of Use
          </span>


          <div className="main">
            <p ><b>Ndoto Mobile</b> is the provider of the CashHub platform, accessible via mobile and desktop/laptop devices. CashHub hosts various content services that can be accessed individually by customers based on a subscription model ("Service(s)"). The terms of this agreement ("Terms and Conditions") govern the relationship between you and Ndoto Mobile and its affiliates (hereinafter "CashHub" or "Us" or "We") regarding your use of CashHub and related Services on CashHub.
               Use of CashHub is also governed by the Privacy Policy which is incorporated herein by reference.</p>
         
         <p>
         <b>WASPA</b> is a non-profit body, founded in 2004, 
        which represents the interests of mobile application service providers operating in South Africa. WASPA provides a neutral forum for members to address issues of common interest and interact with industry stakeholders, network operators, and government bodies.
         </p>
         
         <h3><b>Your Acceptance of These Terms and Conditions</b></h3>

         <ul><b>
          <li>
          Please read these Terms and Conditions and the Privacy Policy carefully before using CashHub and/or any of the services therein. By using CashHub and/or any of the Services therein or submitting information on CashHub, you are agreeing to these Terms and Conditions. If you do not accept these Terms and Conditions, please do not use CashHub or any Services therein.

          </li>
          <li>	We reserve the right, at any time, to modify and update these Terms and Conditions by posting such updated Terms and Conditions on the Ndoto Mobile and CashHub website. Any such updates shall apply to you and your use of CashHub and the Services therein. By continuing to use CashHub, you shall be deemed to have accepted such changes.</li>
         <li>
         You can access CashHub on the URL: https://cashhub.ndotomobile.com or on the Ndoto Mobile website under the services banner to access CashHub. To access and use CashHub, you must be 13 (Thirteen) years of age or older.
         </li>
         
         <li>	Once registered to use CashHub, you give us consent to provide you with various notifications and messages about / based on your interaction with the Service. These notifications will be delivered to you in the form of text messages. These messages include, but are not limited to:</li>
         </b>
        <ul>
          <li>Periodic messages to encourage you to browse or access Services on CashHub or inform you of various features, new or existing;</li>
          <li>Messages inviting you to try alternate versions of the Service based on our knowledge of the device you are using to access CashHub;</li> 
      
       <li>Confirmation messages relating to your registration or deregistration for the Service, and/or when you purchase/subscribe/unsubscribe to any premium services available on the Service.</li>
        </ul>
        <li>	<b>We grant you permission to use CashHub and its Services, in accordance with these Terms and Conditions, provided that:</b></li>
         <ul>
          <li>your use of CashHub is only for your personal, non-commercial purposes;</li>
          <li>you do not copy or distribute any part of CashHub in any medium;</li>
          <li>you do not modify or reverse engineer any part of CashHub; and</li>
          <li>you comply with these Terms and Conditions.</li>
         </ul>
         <li>**Ndoto Mobile is a member of WASPA and is bound by the WASPA Code of Conduct. Customers have the right to approach WASPA to lodge a complaint in accordance with the WASPA complaints procedure. Ndoto Mobile may be required to share information relating to a service or a customer with WASPA for the purpose of resolving a complaint. WASPA website: www.waspa.org.za. CashHub is a subscription service charged at R3/day. By using the service you agree that you are 18 years or older and have the bill payer's permission. Compatible handsets only. To unsubscribe dial USSD 179#. For help call +27 (63) 686-7331. By using the service you agree to receive promotional material relating to this and similar services. To opt-out of marketing dial USSD 179#. Service provided by Ndoto Mobile, Fernbrook Estate, 20 Brookside Crescent, Inchanga Road, Maroeladal 2191.</li>
         </ul>

         <div>
          <h3><b>This Service Must Not Be Used:</b></h3>
          <ul ><b>
            <li>To intentionally engage in illegal conduct;</li>
            <li>To knowingly create, store or disseminate any illegal content;</li>
            <li>To knowingly infringe copyright;</li>
            <li>To knowingly infringe any intellectual property rights; or</li>
            <li>To send spam or promote the sending of spam.</li>
            </b>
          </ul>
          <p><b>We have the right to suspend or terminate the services of any customer who does not comply with these terms and conditions or any other related contractual obligations, and we have the right to take down any content (hosted as part of the service) that it considers illegal or for which it has received a take-down notice.</b></p>
       <ul><b>
        <li>•	You may not use, store, display, publish, transmit, distribute, modify, reproduce, create derivative works of or in any way exploit CashHub, in whole or in part, outside of the specific usage rights granted to you by Ndoto Mobile.</li>
      <li>•	We may change, suspend, or discontinue all or any aspect of CashHub at any time, including the availability of any specific features. In such an event, we shall not be required to provide benefits or other compensation to Subscribers in connection with such discontinued live, or any discontinued parts thereof.</li>
     <li>•	You acknowledge that from time to time, CashHub's availability may be limited due to maintenance, upgrade, or other technical reasons as relevant.</li>
      <li>•	To the extent allowed by law, CashHub is provided on an "as is" and "as available" basis for your use, without warranties of any kind, express or implied. We do not warrant that you will be able to access or use CashHub on any device, or at any time or location, or that CashHub will be uninterrupted or error-free. You agree that we will not be liable to you or to any third party for any unavailability, modification, suspension, or withdrawal of any Content, or any features, part of, or Content of CashHub or failure to make available any advertised Content title or to do so at the advertised time.</li>
    <li>•	You acknowledge that we may appoint third-party companies and individuals to facilitate the provision of CashHub. These third parties have limited access to your information only to the extent necessary for the performance of CashHub and not to disclose or use it for other purposes</li>
    <li>•	These Terms and Conditions apply to all users of CashHub and the Services therein, including users who are also contributors of information, and other materials on CashHub. CashHub may also contain third-party advertisements, content, materials, and/or links to third-party websites that are not owned or controlled by us. We assume no responsibility for the content, privacy policies, or practices of any third-party websites. By using CashHub, you expressly release us from any and all liability arising from your use of any third-party website or as a result of any third-party presence on CashHub.</li>
    <li>•	You agree to use CashHub in good faith and in accordance with all applicable laws, refraining from using the service for illegal purposes, or in a manner that is contrary to any applicable laws, harms the rights and/or interests of others, or in any way damages, disables, or overburdens or impairs CashHub.</li>
    <li>•	You shall abstain from obtaining or attempting to obtain information, messages, graphics, pictures, sound files, and/or images, photographs, recordings, software, and generally any kind of material accessible using means other than those which CashHub provides.</li>
    </b>
     </ul>

     <h3><b>Fees and Payment Terms   </b></h3>
     <ul><b>
      <li>•	You acknowledge and accept that a Service(s) require payment on a once-off or subscription basis, as applicable.</li>
      <li>•	Depending on the chosen payment option for the Service(s) you may be billed ad-hoc, daily, weekly, or monthly for specific Service(s) that you register/subscribe to for CashHub. Subscription and usage are voluntary and occur via the Service. The charges for the Services are subject to change, at Ndoto Mobile's or the relevant third-party content owner's discretion. Any changes therein will be communicated to subscribers with reasonable notice and you will be afforded the option to unsubscribe.</li>
    
    <li>•	Payments for the Service(s) will be levied against the payment method of your choice on the Service.</li>
    <ul>
      <li>o	When paying via your mobile telephone bill, the charge will be taken from your prepaid airtime balance (for TopUp and prepaid users) or added to your account (for postpaid users).</li>
   <li>o	No refunds will be allocated for partial use of the Service.</li>
    </ul>
    <li>•	You will always be advised of the fees payable for the Service and you must first accept the cost prior to subscribing.</li>
    <li>•	When using game content, you acknowledge that the quality of the playback of the content may vary from device to device, and may be affected by a variety of factors, such as your location, the bandwidth available through and/or speed of your Internet connection. Not all content is available in all formats. You are responsible for all Internet access charges.</li>
    </b>
     </ul>
  
<b>
     <h3>Limitation of Liability</h3>
     <ul>
      <li>
     •	You agree that your use of CashHub and its Services is at your sole risk. To the fullest extent permitted by law, we and our officers, directors, employees, and agents disclaim all warranties, express or implied, in connection with CashHub and your use thereof. We make no warranties or representations about the accuracy or completeness of CashHub Services content or the content of any websites or Service linked to CashHub and assume no liability or responsibility for any:
      </li>
      <ul>
        <li>o	errors, mistakes, or inaccuracies of content;</li>
         <li>o	personal injury or property damage of any nature whatsoever resulting from your access to and use of CashHub or its Services;</li>
        <li>o	any interruption or cessation of transmission to or from CashHub;</li>
     <li>o	any bugs, viruses, trojan horses, or the like which may be transmitted to or through CashHub by any third party; or</li>
    <li>o	any errors or omissions in any content or for any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via CashHub. We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through CashHub or any hyperlinked website or featured in any banner or other advertising, and we will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</li>
     </ul>

     <li>•	To the extent permitted by applicable law, in no event shall we or our subsidiaries and affiliates, and their respective officers, directors, owners, agents, employees, representatives, and licensors be liable for any special, incidental, indirect, consequential, punitive, or exemplary losses or damages whatsoever or for loss of profits (including, without limitation, damages for loss of revenue, loss of data, failure to realize expected savings, interruption of activities, or any other pecuniary or economic loss) and whether arising from breach of contract, damages (including negligence), strict liability or otherwise, arising out of the use of or inability to use CashHub or its content, or any Service described or provided through the CashHub.</li>
      <li>•	Save to the extent permitted by law, you agree to indemnify, defend and hold harmless Ndoto Mobile from and against any and all claims, demands, actions, liability, losses, costs, and expenses (including legal fees and expenses) arising from or related to:</li>
     <ul>
      <li>o	any use or alleged use of the CashHub by any other person, whether or not authorized by you;</li>
   <li>o	your breach of these Terms and Conditions, including any breach of your representations and warranties herein;</li>
   <li>o	your violation of any law; or</li>
    <li>o	your use of or inability to use the CashHub, the contents, any hyperlinked website, and any of the Services made available on CashHub.</li>
    
     </ul>
      </ul>

      <h3>Privacy and Data Protection</h3>
      <ul>
        <li>•	For the purpose of CashHub, we will collect information on your MSISDN, name, and preferences. This information will be shared with Genrosys Technologies (Pty) Ltd, our appointed service provider for CashHub and may be transferred across the border to enable the provision of CashHub and its various Services. The Ndoto Mobile's Privacy Policy shall apply in addition to these terms and conditions.</li>
      </ul>


        <h3>List of Services Currently on CashHub</h3>
  <ul>
    <li>
    •	Games Portal Service
    </li>
    <ul>
      <li>o	This is a recurring daily, weekly, or monthly subscription to access four games on the portal. Users can play unlimited games during their subscription period.</li>
   <li>o	In the event that Ndoto Mobile is unable to bill for the full subscription fee for this Service, Ndoto Mobile will implement micro-billing. Micro-billing occurs when a user that is subscribed or subscribing to the Service does not have sufficient funds in their account (airtime balance) to pay for the service. Micro-billing will allow for Ndoto Mobile to charge a discounted subscription in the event that the user's airtime balance is less than the subscription fee.</li>
    </ul>
  </ul>

  <h3>Reward System</h3>

<ul>
  <li>•	Users who achieve top positions in the games may receive airtime as a reward.</li>
</ul>

<h3>How to Opt-Out</h3>
<ul>
  <li>•	If you want to opt out of Services on CashHub, you can dial 135997# to manage all your content services.</li>
  <li>•	Please note you may not opt out of system messages sent to the customer when subscribing, canceling the service, or receiving renewal notifications of their next billing cycle.</li>
</ul>
</b>


         </div>
          </div>

     
        <Footer/>
        </div>
      </div>
    </div>
  );
};
export default Terms;