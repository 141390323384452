import React from "react";
import "../Css/Pending.css";

const BillingPending=()=>{
    
    return(
        <>
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        {/* <h1></h1> */}
                        <h2>Billing Pending</h2>
                    </div>
                </div>
            </div>            
        </>
    );
}
export default BillingPending;