import React from "react";
import Footer from "../Components/Footer";
import score_icon from "../Images/score-icon.png";
import menu_icon from "../Images/menu-icon.svg";
import Menu from "../Components/Menu";

const Faq = () => {
  return (
    <div className="text-unselect">
      <Menu one="inactive"two="inactive"three="inactive"four="inactive"five="inactive"six="active"/>
      <div className="container white-bg">
        <div className="cus-header">
          <div className="col-md-6 col-xs-6">
            <div className="page-icon">
              <img alt="logo" src={score_icon} /> FAQ
            </div>
          </div>
          <div className="col-md-6 col-xs-6">
          <span
              className="cus-menu navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-expanded="false"
            >
              <img alt="logo" src={menu_icon} />
              <span className="cursor-pointer">menu</span>
            </span>
          </div>
        </div>
        <div className="container-area">
          <span className="cus-btn-blue container-area-span">
            {" "}
            FAQ
          </span>

          <div className="score-area">
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....

            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
            Terms & Conditions .....
          </div>
        <Footer/>
        </div>
      </div>
    </div>
  );
};
export default Faq;